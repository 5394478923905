.header {
  display: block;
  min-height: 64px;
  padding: 2em 0;
  text-align: center;
  letter-spacing: -0.02em;
}

.header ul {
  list-style: none;
  padding: 0;
}

.header ul li {
  display: inline-block;
  padding: 0 10px;
}

.header :global(a) {
  color: var(--accents-2);
  font-weight: 400;
}

.header :global(a.active) {
  color: #0070f3;
  font-weight: 600;
}

@media (max-width: 600px) {
  .header {
    padding: .5em 0 2em;
  }
}
