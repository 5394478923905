.centerImage {
    display: flex;
    justify-content: center;
}

.layout h1,
.layout h2,
.layout h3 {
  text-align: center;
}
.highlightLink {
  text-decoration: none;
  color: #36363c;
  display: inline-block;
  padding: 0px 7px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: inset 0 -10px 0 #007fff;
}
.highlightLink:hover {
  box-shadow: inset 0 -55px 0 0 #007fff;
  color: white;
}

.layout iframe {
    border: none;
    width: 100%;
    height: 300vh;
}
